export const storeCurrentRelativeUrl = (key: string) => {
  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;
  const relativeUrl = currentUrl.substring(baseUrl.length);
  sessionStorage.setItem(key, relativeUrl);
}

export const getStoredRelativeUrl = (key: string): string | null => {
  return sessionStorage.getItem(key);
}

