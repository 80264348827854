import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { app } from '@microsoft/teams-js';
import i18n, { updateLocale } from './i18n';
import './App.scss';
import { AppContext } from './model';
import ErrorPage from './components/ErrorPage/ErrorPage';
import SignInPage from './components/SignInPage/SignInPage';
import SignInSimpleStart from './components/SignInPage/SignInSimpleStart';
import SignInSimpleEnd from './components/SignInPage/SignInSimpleEnd';
import HelpPage from './components/HelpPage/HelpPage';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import DepartureBoardPage from './components/DepartureBoardPage/DepartureBoardPage';
import AppConfigurationPage from './components/AppConfigurationPage/AppConfigurationPage';

import ConfigureTabPage from './components/ConfigureTabPage/ConfigureTabPage';
import {
    FluentProvider,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    Theme,
} from '@fluentui/react-components';
import SendMessageToTeamPage from './components/SendMessageToTeam/SendMessageToTeamPage';
import ReportingPage from './components/ReportingPage/ReportingPage';
import FlightBaggageStatusPage from './components/FlightBaggageStatusPage/FlightBaggageStatusPage';
import SendPassengersMessageToTeamPage from './components/SendPassengersMessageToTeamPage/SendPassengersMessageToTeamPage';
import BroadcastMessagingDialogPage from './components/BroadcastMessagingDialogPage/BroadcastMessagingDialogPage';
import { useScrollBarStyle } from './controls';
import BroadcastMessageReportPage from './components/BroadcastMessageReportPage/BroadcastMessageReportPage';

const App = () => {
    const [teamsContext, setTeamsContext] = useState<app.Context>();
    const [subEntityId, setSubEntityId] = useState<string | undefined>();
    const scrollBarStyles = useScrollBarStyle();
    useEffect(() => {
        const init = async () => {
            if (!app.isInitialized()) {
                await app.initialize();
            }
            const context = await app.getContext();
            setTeamsContext(context);
            if (context.page.subPageId) {
                setSubEntityId(context.page.subPageId);
            }
            app.registerOnThemeChangeHandler((theme) => {
                setTeamsContext((prevState) => {
                    return {
                        ...prevState!,
                        app: {
                            ...prevState!.app,
                            theme: theme,
                        },
                    };
                });
            });
        };

        init().catch(console.log);
        updateLocale();
    }, []);

    const getAppDom = useCallback(() => {
        return (
            <Suspense fallback={<div></div>}>
                <div className={scrollBarStyles}>
                    <div className="appContainer">
                        <Routes>
                            <Route path="/errorpage" element={<ErrorPage />} />
                            <Route
                                path="/errorpage/:id"
                                element={<ErrorPage />}
                            />
                            <Route path="/signin" element={<SignInPage />} />
                            <Route
                                path="/signin-simple-start"
                                element={<SignInSimpleStart />}
                            />
                            <Route
                                path="/signin-simple-end"
                                element={<SignInSimpleEnd />}
                            />
                            <Route path="/help" element={<HelpPage />} />
                            {/*<Route*/}
                            {/*    path="/findFlightPassengers/:flightTeamId"*/}
                            {/*    element={<FindFlightPassengersPage />}*/}
                            {/*/>*/}
                            <Route
                                path="/departureBoard"
                                element={<DepartureBoardPage />}
                            />
                            {/*<Route*/}
                            {/*    path="/flightsPassengersBoard"*/}
                            {/*    element={<FlightsPassengersBoardPage />}*/}
                            {/*/>*/}
                            <Route
                                path="/appConfiguration"
                                element={<AppConfigurationPage />}
                            />
                            <Route
                                path="/configureTab"
                                element={<ConfigureTabPage />}
                            />
                            <Route
                                path="/sendMessageToTeam/:flightTeamId"
                                element={<SendMessageToTeamPage />}
                            />
                            <Route
                                path="/broadcastMessaging/:dataKey"
                                element={<BroadcastMessagingDialogPage />}
                            />
                            <Route
                                path="/sendPassengersMessageToTeam/:flightTeamId"
                                element={<SendPassengersMessageToTeamPage />}
                            />

                            <Route path="/flightBaggageStatus" element={<FlightBaggageStatusPage />} />
                            <Route path="/flightBaggageStatus/:flightTeamId" element={<FlightBaggageStatusPage subEntityId={subEntityId} />} />
                            <Route path="/reporting"
                                element={<ReportingPage />}
                            />
                            <Route
                                path="/broadcastMessageReport"
                                element={<BroadcastMessageReportPage />}
                            />
                            <Route path="/*" element={<NotFoundPage />} />
                        </Routes>
                    </div>
                </div>
            </Suspense>
        );
    }, [scrollBarStyles, subEntityId]);

    const getThemeComponent = useCallback(() => {
        if (!teamsContext) {
            return <></>;
        }

        const themeName = teamsContext?.app?.theme;

        let themeClassName: string;
        let fluentTheme: Theme;
        switch (themeName) {
            case 'dark':
                themeClassName = 'darkContainer';
                fluentTheme = teamsDarkTheme;
                break;
            case 'contrast':
                themeClassName = 'highContrastContainer';
                fluentTheme = teamsHighContrastTheme;
                break;
            default:
                themeClassName = 'defaultContainer';
                fluentTheme = teamsLightTheme;
                break;
        }

        return (
            <AppContext.Provider
                value={{
                    teamsContext: teamsContext,
                }}
            >
                <FluentProvider theme={fluentTheme} dir={i18n.dir()}>
                    <div className={themeClassName}>{getAppDom()}</div>
                </FluentProvider>
            </AppContext.Provider>
        );
    }, [teamsContext, getAppDom]);

    return getThemeComponent();
};

export default App;
